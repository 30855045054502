import type { Ref } from 'vue'

import type { SuggestionType } from '@/repository/search.ts'

export default function useEmailSuggestions(query: Ref<string | undefined>) {
  const { t } = useI18n()

  const suggestionType = ref<SuggestionType>('none')
  const suggestions = ref<string[]>([])

  const suggestionsHeader = computed(() => {
    if (suggestionType.value === 'recent') return t('auto_suggest.email.header.recent') as string
    if (suggestionType.value === 'suggest') return t('auto_suggest.email.header.suggest') as string
    else return ''
  })

  const isFetching = ref(false)

  const suggestionsTimeout = ref(0)

  const isLoading = computed(() => isFetching.value || suggestionsTimeout.value !== 0)

  const error = ref<Error | null>(null)

  const { searchRepository } = useApi()

  const updateSuggestions = async (query?: string) => {
    isFetching.value = true

    try {
      const { type, values } = await searchRepository.getEmailSuggestions(query ?? '')

      suggestionType.value = type
      suggestions.value = values

      error.value = null
    } catch (e: unknown) {
      error.value = e as Error
    }

    isFetching.value = false
  }

  watch(query, newQuery => {
    if (suggestionsTimeout.value) {
      window.clearTimeout(suggestionsTimeout.value)
    }

    suggestionsTimeout.value = window.setTimeout(() => {
      void updateSuggestions(newQuery)
      suggestionsTimeout.value = 0
    }, 300)
  })

  return {
    suggestionType,
    suggestions,
    suggestionsHeader,
    isFetching,
    isLoading,
    error,
    updateSuggestions,
  }
}
